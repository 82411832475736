import axiosInstance from './index'
import { ResponseData } from './types'

export const get = async <T>(url: string, params?: Record<string, any>): Promise<T> => {
    try {
        const response = await axiosInstance.get<ResponseData<T>>(url, { params })
        return response.data.response.data
    } catch (error) {
        console.error('Error in GET request:', error)
        throw error
    }
}

export const post = async <T>(url: string, data?: Record<string, any>): Promise<T> => {
    try {
        const response = await axiosInstance.post<ResponseData<T>>(url, data)
        return response.data.response.data
    } catch (error) {
        console.error('Error in POST request:', error)
        throw error
    }
}

export const patch = async <T>(url: string, data?: Record<string, any>): Promise<T> => {
    try {
        const response = await axiosInstance.patch<ResponseData<T>>(url, data)
        return response.data.response.data
    } catch (error) {
        console.error('Error in PATCH request:', error)
        throw error
    }
}

export const del = async <T>(url: string, params?: Record<string, any>): Promise<T> => {
    try {
        const response = await axiosInstance.delete<ResponseData<T>>(url, { params })
        return response.data.response.data
    } catch (error) {
        console.error('Error in DELETE request:', error)
        throw error
    }
}
