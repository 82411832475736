export const constructProductRoute = (brandId: string, productId?: string) => {
    if (productId) {
        return `/brands/${brandId}/products/${productId}`
    } else {
        return `/brands/${brandId}/products`
    }
}

export const constructBrandRoute = (brandId?: string) =>{
    if(brandId){
        return `/brands/${brandId}`
    }
    return `/brands`
}
