import { getApp, getApps, initializeApp } from 'firebase/app'
import { connectFirestoreEmulator, getFirestore, initializeFirestore } from 'firebase/firestore'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getDatabase } from 'firebase/database'
import { connectStorageEmulator, getStorage } from 'firebase/storage'

const config = {
    app: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
        appId: process.env.REACT_APP_APPID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID,
        baseURL: process.env.REACT_APP_BASE_URL,
    },
    alpha: {
        apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROD_PROJECT_ID,
        storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_ID,
        appId: process.env.REACT_APP_ALPHA_APPID,
        measurementId: process.env.REACT_APP_ALPHA_MEASUREMENT_ID,
        baseURL: process.env.REACT_APP_PROD_BASE_URL,
    },
    beta: {
        apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROD_PROJECT_ID,
        storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_ID,
        appId: process.env.REACT_APP_PROD_APPID,
        measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID,
        baseURL: process.env.REACT_APP_PROD_BASE_URL,
    },
    dev: {
        apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_DEV_PROJECT_ID,
        storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_ID,
        appId: process.env.REACT_APP_DEV_APPID,
        measurementId: process.env.REACT_APP_DEV_MEASUREMENT_ID,
        baseURL: process.env.REACT_APP_DEV_BASE_URL,
    },
}

const makeConfig = () => {
    const branch = process.env.GIT_BRANCH
    const channel = process.env.REACT_APP_FIREBASE_DEPLOY_CHANNEL

    const channelConfigMap = {
        alpha: config.alpha,
        alpha_preview: config.alpha,
        dev: config.dev,
        dev_preview: config.dev,
        beta: config.beta,
    }

    const branchConfigMap = {
        main: config.beta,
        alpha: config.alpha,
        beta: config.beta,
    }

    // Return the config based on the channel if it exists in the map.
    if (channelConfigMap[channel]) {
        return channelConfigMap[channel]
    }

    // If no channel config is found, return the config based on the branch,
    return branchConfigMap[branch] || config.dev
}

const { baseURL, ...rest } = makeConfig()

let BASE_URL = baseURL

const app = getApps().length === 0 ? initializeApp(rest) : getApp()

// const db = getFirestore(app)
let db

if (JSON.parse((process.env.NEXT_PUBLIC_USE_EMULATOR || 'false').toLowerCase())) {
    db = initializeFirestore(app, { experimentalForceLongPolling: true })
} else {
    db = getFirestore(app)
}
const auth = getAuth(app)
const functions = getFunctions(app)
const rtdb = getDatabase(app)
const storage = getStorage(app)
if (JSON.parse((process.env.NEXT_PUBLIC_USE_EMULATOR || 'false').toLowerCase())) {
    BASE_URL = process.env.REACT_APP_EMULATOR_BASE_URL
    connectFunctionsEmulator(functions, '127.0.0.1', 5001)
    connectFirestoreEmulator(db, '127.0.0.1', 8081)
    connectAuthEmulator(auth, 'http://127.0.0.1:4001')
    connectStorageEmulator(storage, '127.0.0.1', 9199)
}

export { auth, db, rtdb, storage, functions, BASE_URL }
// connectFunctionsEmulator(functions, 'localhost', 5001)
