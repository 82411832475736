import { db } from '../../../firebase_config/firebase_config'
import { collection, collectionGroup, CollectionReference, getDocs, query, where } from 'firebase/firestore'
import { BrandLibraryTalentData } from '../../../types'
import { Brands, Permissions } from '@viscapmedia/viscap-schema-types'
import { constructBrandRoute } from '../../axios/routes'
import { patch, post, get } from '../../axios/request'

export const getAllBrands = async (): Promise<Brands[]> => {
    const url = constructBrandRoute()
    const res: Brands[] = await get<Brands[]>(url)
    return res 
}

export const getUserBrands = async (ids: string[]): Promise<Brands[]> => {
    const url = 'brands/user-brands'
    const res = await post<Brands[]>(url, { brandIds: ids })
    return res
}

export const getTalentBrands = async (talentId: string): Promise<Brands[]> => {
    const url = `brands/talent-brands/${talentId}`
    const res = await get<Brands[]>(url)
    return res
}

export const getBrand = async (brandId: string): Promise<Brands> => {
    const url = constructBrandRoute(brandId)
    const res: Brands = await get<Brands>(url)
    return res
}

export const saveBrandFirestore = async (brand: Partial<Brands>) => {
    const url = constructBrandRoute(brand?.id)
    await patch(url, { fields: brand })
}

export const brandAssignUsers = async (brandId: string, userIds: string[]) => {
    try {
        const url = constructBrandRoute(brandId)
        await patch<{ updatedDoc: Brands }>(`${url}/assign-users`, { userIds })
    } catch (error) {
        throw new Error(error)
    }
}

export const brandRevokeUsers = async (brandId: string, userIds: string[]) => {
    try {
        const url = constructBrandRoute(brandId)
        await patch<{ updatedDoc: Brands }>(`${url}/revoke-users`, { userIds })
    } catch (error) {
        throw new Error(error)
    }
}

export const brandRequestAccess = async (brandId: string, userId: string) => {
    try {
        const url = constructBrandRoute(brandId)
        await patch<{ updatedDoc: Brands }>(`${url}/access-request/${userId}`, { userId })
    } catch (error) {
        throw new Error(error)
    }
}

export const getBrandActors = async (data: {
    brandId: string
    actorIds: string[]
    fetchAll?: boolean
}): Promise<BrandLibraryTalentData[]> => {
    const { brandId, actorIds, fetchAll } = data

    const brandActorsCollectionRef: CollectionReference<BrandLibraryTalentData> = collection(
        db,
        'brands',
        brandId,
        'talentSuggestions'
    ) as CollectionReference<BrandLibraryTalentData>

    let actorsQuery
    if (fetchAll) {
        actorsQuery = brandActorsCollectionRef
    } else {
        actorsQuery = query(brandActorsCollectionRef, where('id', 'in', actorIds))
    }

    const querySnapshot = await getDocs(actorsQuery)

    return querySnapshot.docs.map((doc) => doc.data() as BrandLibraryTalentData)
}

export const checkNameUnique = async (name: string) => {
    const url = '/brands/check-name-unique'
    const res = await post<{ isUnique: boolean }>(url, { name })
    return res.isUnique
}

export const addBrand = async (teamId: string, brandData: Brands, defaultPermissions: Permissions) => {
    const url = constructBrandRoute()
    const data = { teamId, brandData, defaultPermissions }
    const res = await post<{ brandId: string }>(url, data)
    return res
}
